<script setup>
import { useI18n } from "vue-i18n";

const { locale } = useI18n();
const { $intercom, $i18n } = useNuxtApp();
const user = useCurrentUser();
const {
  cookiesEnabled,
  cookiesEnabledIds,
  isConsentGiven,
  isModalActive,
  moduleOptions,
} = useCookieControl();

if (cookiesEnabledIds.value && cookiesEnabledIds.value.includes("intercom")) {
  $intercom.boot({});
  if (user.value) {
    $intercom.update({
      user_id: user.value?.uid,
      email: user.value.email,
      language_override: $i18n.locale.value,
    });
  }
}

watch(
  () => $i18n.locale.value,
  () => {
    if (user.value) {
      $intercom.update({
        user_id: user.value?.uid,
        email: user.value.email,
        language_override: $i18n.locale.value,
      });
    }
  },
);

// example: react to a cookie being accepted
watch(
  () => cookiesEnabledIds.value,
  (current, previous) => {
    if (!previous?.includes("intercom") && current?.includes("intercom")) {
      const { $intercom } = useNuxtApp();
      $intercom.boot({});
      if (!user.value) {
        return;
      }
      $intercom.update({
        user_id: user.value?.uid,
        email: user.value.email,
      });
    }
  },
  { deep: true },
);

const cookieSupportedLocales = [
  "en",
  "fr",
  "bg",
  "hr",
  "cs",
  "da",
  "nl",
  "fi",
  "de",
  "hu",
  "it",
  "lt",
  "pl",
  "ro",
  "sk",
  "es",
  "sv",
];

const currentLocale = computed(() =>
  cookieSupportedLocales.includes(locale.value) ? locale.value : "en",
);
</script>

<template>
  <!-- i dont even know why this seems to fix language switching :( -->
  <span></span>

  <CookieControl :locale="currentLocale" />
</template>

<style scoped></style>
